import loadScriptInBody from '@/assets/javascript/loadScriptInBody'

export const ACE_UTIL = {
  fixAceeIOSBug: function () {
    if (typeof navigator === 'object' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      window.MSStream = {}
    }
  },
  loadACELibraries: function () {
    this.fixAceeIOSBug()
    loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js')
      .then(() => {
        loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
        loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
      })
  },
  initEditor: function (id, language) {
    window.ace.require('ace/ext/language_tools')
    window.ace.config.set('basePath', '/assets/javascript/ace')

    let editor = window.ace.edit(id)

    if ((localStorage.getItem('JDoodle-Theme') === 'dark')) {
      editor.setTheme('ace/theme/gruvbox')
    } else {
      editor.setTheme('ace/theme/xcode')
    }

    if (language) {
      editor.getSession().setMode('ace/mode/' + language)
    }
    editor.renderer.setShowGutter(false)
    editor.renderer.setAnimatedScroll(true)
    editor.renderer.setShowPrintMargin(false)

    editor.setOptions({
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: false
    })
    editor.$blockScrolling = Infinity
    return editor
  },
  heightChangeFunction: function (id) {
    let editor = window.ace.edit(id)

    let newHeight = editor.getSession().getScreenLength() * editor.renderer.lineHeight +
      editor.renderer.scrollBar.getWidth()
    if (id === 'output') {
      newHeight += 40
    }

    $('#' + id).height(newHeight.toString() + 'px')
    editor.resize()
  },
  changeLanguage: function (editor, language) {
    if (language) {
      editor.getSession().setMode('ace/mode/' + language)
      if (this.language === 'whitespace') {
        editor.setShowInvisibles(true)
      } else {
        editor.setShowInvisibles(false)
      }
    }
  }
}
